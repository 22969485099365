import NextImage from "next/image";
import type { ReactEventHandler } from "react";
import { memo, useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import log from "src/helpers/log";
import MDiv from "../MDiv";
import useBlurHash from "./hooks/useBlurHash.web";
import useParseImageDim from "./hooks/useParseImageDim";
import useParseSizes from "./hooks/useParseSizes";
import useImageStore from "./stores/useImageStore";
import { ImageProps } from "./types";

const Image = ({
	alt,
	src,
	dim,
	contentFit = "cover",
	contentPosition,
	style,
	blurHash,
	recyclingKey,
	priority = "normal",
	pointerEvents,
	sizes,
	onLoad,
	onError,
	testID,
	unoptimized: unoptimizedProp,
	enteringAnimation = true,
	loading,
	blurRadius,
	muteErrors,
}: ImageProps) => {
	const { width, height, fill } = useParseImageDim(dim);
	const parsedSizes = useParseSizes(sizes);
	const stringRepresentation = typeof src === "string" ? src : src.src;
	const initialDidLoad = useMemo(() => {
		const state = useImageStore.getState();
		return state.imageLookup[stringRepresentation]?.didLoad || false;
	}, [stringRepresentation]);
	const [didLoad, setDidLoad] = useState(initialDidLoad);

	const handleLoad = useCallback<ReactEventHandler<HTMLImageElement>>(
		(e) => {
			useImageStore.setState((state) => {
				state.imageLookup[stringRepresentation] = {
					didLoad: true,
				};
				return state;
			});
			setDidLoad(true);
			onLoad?.({
				cacheType: "memory",
				height: e.currentTarget.naturalHeight,
				width: e.currentTarget.naturalWidth,
				url: typeof src === "string" ? src : null,
			});
		},
		[onLoad, src, stringRepresentation],
	);
	const handleError = useCallback<ReactEventHandler<HTMLImageElement>>(() => {
		onError?.({
			error: "Image failed to load",
		});
		if (!muteErrors) {
			log.warn("Image failed to load");
		}
	}, [muteErrors, onError]);
	const unoptimized = useMemo(() => {
		return unoptimizedProp || (typeof src === "string" && src.includes(".cloudfront.net"));
	}, [src, unoptimizedProp]);
	const blurHashBase64 = useBlurHash(blurHash, initialDidLoad);

	return (
		<View
			testID={testID}
			style={[{ width, height, overflow: "hidden" }, style]}
			pointerEvents={pointerEvents}
		>
			{blurHashBase64 && (
				// eslint-disable-next-line @next/next/no-img-element
				<img
					src={blurHashBase64}
					alt={alt}
					style={{
						position: "absolute",
						objectFit: contentFit,
						width: "110%",
						height: "110%",
						margin: "-5%",
						filter: "blur(10px)",
					}}
				/>
			)}
			<MDiv
				initial={
					enteringAnimation || blurHashBase64
						? {
								opacity: initialDidLoad || didLoad ? 1 : 0,
							}
						: undefined
				}
				animate={
					enteringAnimation || blurHashBase64
						? {
								opacity: initialDidLoad || didLoad ? 1 : 0,
							}
						: undefined
				}
				transition={{
					duration: 0.2,
				}}
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				<NextImage
					key={recyclingKey}
					loading={loading}
					fill={fill}
					width={width}
					height={height}
					alt={alt}
					src={src}
					style={{
						objectFit: contentFit,
						objectPosition: contentPosition,
						filter: blurRadius ? `blur(${blurRadius}px)` : undefined,
					}}
					priority={priority === "high"}
					sizes={parsedSizes}
					onLoad={handleLoad}
					onError={handleError}
					unoptimized={unoptimized}
				/>
			</MDiv>
		</View>
	);
};
export default memo(Image);
